import { auth, Logout } from "services/firebase";
import {  sendSignInLinkToEmail , isSignInWithEmailLink, signInWithEmailLink} from "firebase/auth";

const url = process.env.REACT_APP_DECA_URL;

function GetEmailUrl () {
  if (process.env.REACT_APP_STAGE === 'local') {
    return process.env.REACT_APP_AUTHEMAIL_URL_DEVELOPMENT;
  } else {
    return process.env.REACT_APP_AUTHEMAIL_URL_PRODUCTION;
  }
}

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: GetEmailUrl(),
  // This must be true.
  handleCodeInApp: true,
};

export function GetUserData() {
  let email = window.localStorage.getItem('emailForSignIn');
  let username = window.localStorage.getItem('usernameForSignIn');
  let wallet = window.localStorage.getItem('walletForSignIn');
  if (!username || !wallet || !email) {
    return null;
  }
  return { username, wallet, email}
}

export async function RemoveData() {
  window.localStorage.removeItem('emailForSignIn');
  window.localStorage.removeItem('usernameForSignIn');
  window.localStorage.removeItem('walletForSignIn');
  //console.log("remove data")
  await Logout();
}

export async function SendVerifiedEmail(email, username, wallet) {
  try {
    //console.log(actionCodeSettings)
    //const actionCodeSettings = GET
   sendSignInLinkToEmail(auth, email, actionCodeSettings);
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    window.localStorage.setItem('emailForSignIn', email);
    window.localStorage.setItem('usernameForSignIn', username);
    window.localStorage.setItem('walletForSignIn', wallet);
  } catch(error) {
    console.log("email error",error);
  }
}

export async function CheckIfSignIn() {

  // if (window.location.href.indexOf("finishSignUp") > -1) {
  //   let email = window.localStorage.getItem('emailForSignIn');
  //   let username = window.localStorage.getItem('usernameForSignIn');
  //   let wallet = window.localStorage.getItem('walletForSignIn');
  //   if (!email || !username || !wallet) {
  //     email = window.prompt('Please provide your email for confirmation (private browsing)');
  //     window.localStorage.setItem('emailForSignIn', email);
  //   }
  // }
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn');
    let username = window.localStorage.getItem('usernameForSignIn');
    let wallet = window.localStorage.getItem('walletForSignIn');
    if (!email || !username || !wallet) {
     // console.log('here 0')
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
     // email = window.prompt('Please provide your email for confirmation (private browsing)');
    //  username = window.prompt('Please provide your Deca username for confirmation (private browsing)');
     // wallet = window.prompt('Please provide your Deca wallet for confirmation (private browsing)');

     // console.log(email, username,wallet)
      // window.localStorage.setItem('emailForSignIn', email);
      // window.localStorage.setItem('usernameForSignIn', username);
      // window.localStorage.setItem('walletForSignIn', wallet);
      RemoveData();
      return {status:'Private'};
    }
    // check again that username / wallet are correct -> prevent hack
    const a = await CheckAuthCredentials(username, wallet);
    if(!a) { 
      //console.log("aa")
      RemoveData();
      return {status:'NA'};
    }
    // The client SDK will parse the code from the link for you.
    try {
      
      const res = await signInWithEmailLink(auth, email, window.location.href);
      //console.log("res", res)
      // Clear email from storage.
     // window.localStorage.removeItem('emailForSignIn');
      return {status:'Success', email, username, wallet};
    } catch(error) { 
     console.log("sigin error");
    // console.log(error)
      return {status:'Error'};
    }
  }

  return {status:'NA'};
}

export async function CheckAuthCredentials(username, address) {
  // if (process.env.REACT_APP_STAGE === 'local'  || process.env.REACT_APP_STAGE === 'staging' ) {
  //   return true;
  // }
  if (process.env.REACT_APP_STAGE === 'local' ) {
    return true;
  }
  const tmpUrl = url + `username=${username}&address=${address}`;
  try {
    const res = await fetch(tmpUrl);
    const data = await res.json();
  
    if(data.isAllowed) {
      return true;
    } else {
     return false;
    }
  } catch( error) {
    console.log(error);
    return false;
  }

}