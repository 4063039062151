import * as React from 'react';
import {Box, Grid} from '@mui/material/';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

function Copyright() {
  return (
    <Typography variant="subtitle2" sx={{ mt: 0,  mb:1 }} color="#FFFF" align="left">
      {'©'}
      <Link color="inherit" href="#" target="_blank" style={{ textDecoration: 'none' }}>
      </Link>{' '}
      {new Date().getFullYear()}
      {' Mentaport Inc'}
    </Typography>
  );
}

function PowerByMentaport() {
  return (
    <Grid container alignItems="right" justifyContent="right" sx={{ mt: 1 }}>                                   
      <Typography variant="subtitle2" sx={{ mt: 0,  mb:2 }} color="#FFFF" align="right">
      Powered by 
      </Typography>
      <Link href="https://www.mentaport.com/"  target="_blank" sx={{ ml: 0.25}}  > 
          <img src="/images/logo_pink1.png" alt="mentaport" width={80} sx={{ display: { xs: 'none', md: 'flex'}}} />   
      </Link>
  </Grid>
    
  );
}
export default function Footer() {
return (

  <Box
  component="footer"
  sx={{
    py: 0,
    px: 0,
    mt: 'auto',
    backgroundColor: (theme) => theme.palette.grey[900],
  }}
>
   {/* <Box component="footer" display='flex' flex-direction= "column" sx={{ py: 2,pb:2, m:0}} >  */}
    {/* <Container disableGutters={true} maxWidth="xl"> */}
      {/* <Grid container direction="row" justifyContent="flex"  alignItems="center">
        <Grid item xs={6} md ={6} ></Grid>                          
        <Grid item xs={6} md ={6} textAlign="right">                                    
          <Grid container sx={{mb:1, pr:1}} alignItems="right" justifyContent="right">
            <PowerByMentaport />
          </Grid >
        </Grid>
      </Grid> */}

      <Divider light style={{width:'100%'}}  />
      <Grid container direction="row" justifyContent="flex"  alignItems="center" sx={{ pl:1 }}>
        <Grid item xs={6} md ={6} >  
          <Copyright />
        </Grid>                          
        <Grid item xs={6} md ={6} textAlign="right" sx={{ pr:1 }}>
          <PowerByMentaport />
        </Grid>
      </Grid>
      
    {/* </Container > */}
  </Box>

);
}