import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously,   signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics";
// ==============================|| FIREBASE SETUP  ||============================== //

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: process.env.REACT_APP_ATH,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BS,
  messagingSenderId: process.env.REACT_APP_MESSAGE_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASURE_ID
};
  

// Initialize Firebase and Firebase Authentication
firebase.initializeApp(firebaseConfig)

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

// signInAnonymously(auth)
//   .then(() => {
//     // Signed in..
//   })
//   .catch((error) => {
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     // ...
// });
const db = getFirestore(app)

export {firebase, app, auth, db};

// export async function Anonymously() {
//   try {
//     console.log("here", auth);
//     const res = await signInAnonymously(auth);
//     console.log("done auth aa")
//     return true;
//   } catch(error) {
//     console.log("error", error)
//     return false;
//   }
// }

export async function Logout () {
 // console.log('Logout');
  signOut(auth);
}