import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { useTheme } from "@mui/material/styles";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:280,
  textAlign: 'center',
  bgcolor: '#403657d6',
 // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

export default function BasicModal(props) {
  const { title, info, info2, btnText, useImg, link} = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();

  return (
    <Box
    display="flex"
    justifyContent="right"
    alignItems="center"
    sx={{ mt: 2, mr:0,mb:1,ml:1,mr:1}}>
      <Button onClick={handleOpen} variant="outlined"  sx={{ minWidth: 100, padding: 0, pl:1,pr:1, margin: 0 }} >{btnText}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <Box sx={style}>
        <Typography id="modal-modal-title" color="inherit" variant="h1" >
        {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1, p:0, textAlign:'left', fontSize:16 }}>
          {info}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1, p:0, textAlign:'left', fontSize:16 }}>
          {info2}
        </Typography>
        {useImg && (
        <img
          sx={{ display: { xs: 'none', md: 'flex'} , }}
          src={link}
          width={220}
          alt="image"
        />)}
        
      </Box>
    </Modal>
  </Box>
  );
}