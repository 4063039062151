export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    
    return {
        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.darkTextPrimary
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 510,
                    fontSize: '1.2rem',
                    borderRadius: '5px',
                    padding:'5px',
                  
                    // '&:hover': {
                    //   color: theme.menuSelectedBack
                    // },
                    // '@media (hover: none)': {
                    //     backgroundColor: 'transparent',
                    // },
                },
            
            }
        },
       
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: '24px',
                    borderRadius: '15px'
                },
            }
        },
     
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: theme.menuSelected,
                        backgroundColor: theme.menuSelectedBack,
                        '&:hover': {
                            backgroundColor: theme.menuSelectedBack,
                            '@media (hover: none)': {
                                backgroundColor: 'transparent',
                              },
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.menuSelectedBack,
                        color: theme.menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        },
                        '@media (hover: none)': {
                            backgroundColor: 'transparent',
                        },
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark 
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    // background: bgColor,
                    margin:'0px',
                    padding:'0px',
                   
                    '& .MuiOutlinedInput-notchedOutline': {
                        // borderColor: theme.colors?.grey400,
                        borderRadius:  '5px',
                    //    margin:'0px',
                    //    padding: '0px',
                    },
                    '&:hover $notchedOutline': {
                        // borderColor: theme.colors?.primaryLight
                    },
                    '&.MuiInputBase-multiline': {
                       // padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: bgColor,
                    padding: '5px',
                    borderRadius:  '5px',
                    margin:'0px',
                    height:'15px',
                    // '&.MuiInputBase-inputSizeSmall': {
                    //     padding: '10px 14px',
                    //     '&.MuiInputBase-inputAdornedStart': {
                    //         paddingLeft: 0
                    //     }
                    // }
                },
                // inputAdornedStart: {
                //     paddingLeft: 4
                // },
                // notchedOutline: {
                //     borderRadius:  '25px'
                // }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
       
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        }
    };
}
