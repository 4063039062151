
import {MentaportSDK, MentaportTypes, MentaportUtils} from "@mentaport/sdk-web"

const geofire = require('geofire-common');


const errorMessage = `\nWe can't verify your location. Refresh your page and try again.\n
On Safari: \n Go to 'Settings > Safari > Location > Allow' turned on.\n\nOn Chrome: \nGo to 'Settings > Chrome > Location > While Using the App' turned on.`;


let sdkClient = null;
let errorAlert = false;

// latitude: 37.755191, 
// longitude: -122.442465,
//let closestRule = null;25.7916077, -80.1331351
let  closestRule = {
  latitude: 37.755191, 
  longitude:-122.442465,
  ruleId:"uCWw5sfyDJUrKr7vZHCW",
  ruleInfo:'mariale_1'
}

let timestampRule = 0;
let currentAngle = null;
if (process.env.REACT_APP_STAGE === 'prod' || process.env.REACT_APP_STAGE === 'staging') {
  closestRule = null;
}

// distance greater than this, dont even consider: show message to user "to far"
// 2 miles
const MAX_THRESHOLD = 2; 
// start showing more accurate distance (ft)
const COLOR_1_THRESHOLD = 0.5;
const COLOR_2_THRESHOLD = 0.2;
const FEET_THRESHOLD = 0.04;
const MIN_THRESHOLD = 20;

const METER_FEET = 3.28084;
const METER_MILES = 0.000621371;
const MILES_METERS = 1609;

const RULE_RECHECK_TIME = 45000; // 45 sec to re check closest rule

console.log(process.env.REACT_APP_STAGE)
function GetContractKey () {
  if (process.env.REACT_APP_STAGE === 'local') {
    return process.env.REACT_APP_CONTRACT_KEY_DEVELOPMENT;
  } else {
    return process.env.REACT_APP_CONTRACT_KEY;
  }
}

function GetBaseURL () {
  if (process.env.REACT_APP_STAGE === 'local') {
    return process.env.REACT_APP_BASE_URL_DEVELOPMENT;
  } else {
    return process.env.REACT_APP_BASE_URL_PRODUCTION;
  }
}

// Function to Init SDK
export function InitMentaportSDK() {
  if(sdkClient == null) {
    const url =  GetBaseURL();
    // for this experience has to be mobile:
    if(!CheckIfCompatible()) {
      //console.log("Not compatible");
      if (process.env.REACT_APP_STAGE === 'prod') {
        return false;
      }
    }
    sdkClient = new MentaportSDK(
      {
        baseUrl:url,
        apiKey: process.env.REACT_APP_MENTAPORT_API_KEY,
      }, true
    );
  } else {
    console.log("Already InitMentaportSDK")
  }
  return true;
}

// Function to check if compatible device
export function CheckIfCompatible() {
  return MentaportUtils.checkIsMobile() && MentaportUtils.checkSupportGeoLocation();
}

export function GetUserInfo() {
  const posInfo = sdkClient.getPositionStore();
 // const coor = sdkClient.getCurrentCoordStore();
  //console.log(posInfo)
  if(posInfo.ipInfo != undefined) {
    return {
      ip: posInfo.ipInfo.ip,
      ip_lat : posInfo.ipInfo.latitude,
      ip_lon : posInfo.ipInfo.longitude,
      city:  posInfo.ipInfo.city,
      latitude:posInfo.coordinate.latitude,
      longitude: posInfo.coordinate.longitude
    }
  }
  return {
    ip: '',
    ip_lat : '',
    ip_lon : '',
    city: '',
    latitude:posInfo.coordinate.latitude,
    longitude: posInfo.coordinate.longitude
  }
}

export function GetAngle() {
  if(currentAngle != null) {
    return currentAngle;
  }
  return NaN;
}

export function LocationStarted() {

  if(sdkClient === null) {
    return {
      status:"Not set"
    };
  }

  const info = sdkClient.getInfoStore();
  if(info.status === MentaportTypes.Status.COLLECTING || info.status === MentaportTypes.Status.SUCCESS) {
    errorAlert = false;
    return {
      status:"Start",
    };
  }
  if(info.status === MentaportTypes.Status.INIT ) {
    return {
      status:"Loading",
    };
  }
  if(info.status === MentaportTypes.Status.ERROR ) {
    if(!errorAlert) {
      errorAlert = true;
      alert(info.msg);
    }

    return {
      status:info.status,
      msg: info.msg
    };
  }
  if(info.status === MentaportTypes.Status.BLOCK ) {
    if(!errorAlert) {
      errorAlert = true;
      alert(info.msg);
    }

    return {
      status:info.status,
      msg: info.msg
    };
  }
}

export async function CheckLocationDisHeading(checkRules) {

  if(sdkClient === null) {
    return {
      status:MentaportTypes.Status.ERROR,
      message:"MentaportSDK not set"
    };
  }

  try {
    const info = sdkClient.getInfoStore();
    if(info.status === MentaportTypes.Status.INIT) {
      return {
        status:"LOADING",
        message:"Loading pos"
      };
    }
    if(info.status === MentaportTypes.Status.ERROR || info.status === MentaportTypes.Status.BLOCK ) {
      // get ip again:
      sdkClient.geoService.findIpAddress();
      return {
        status:info.status,
        message:errorMessage
       // message:info.msg,
      };
    }

    const posInfo = sdkClient.getPositionStore();
    if(posInfo.ipInfo && posInfo.ipInfo.city != 'Miami') {
      return {
        status:'ERROR',
        message:errorMessage
        //message:"We are having trouble locating you. Please try again."
      };
    } 

    // get closest rule staging
    if(process.env.REACT_APP_STAGE === 'prod' || process.env.REACT_APP_STAGE === 'staging') {
      // every RULE_RECHECK_TIME or when it forces to check rules again
      if(checkRules|| closestRule === null || (Date.now() - timestampRule) > RULE_RECHECK_TIME) {
        const rule = await sdkClient.getContractClosestMintRule(GetContractKey(), MAX_THRESHOLD * MILES_METERS);
         // console.log(rule)
        // once a minute check if rule has changed
        timestampRule = Date.now();
        if(rule.status === MentaportTypes.Status.SUCCESS) {
          closestRule = rule.data;
        } else if(rule.status === MentaportTypes.Status.FAILED) {
          return {
            status:"NO_RULES",
            message:"No active NFTs at this time near you"
          };
        } else {
          return {
            status:"LOADING",
            message:"Loading pos"
          };
        }
      }
    }
 
    const route = sdkClient.getRouteStore();
    let result = {
      status:info.status,
      message:info.msg,
      angle:0,
      distance:0,
      disThresh:0,
      ruleId:closestRule.ruleId,
      ruleInfo:closestRule.ruleInfo,
      measurment:'miles',
      latitude:posInfo.coordinate.latitude,
      longitude: posInfo.coordinate.longitude,
      walking: posInfo.walkingTime,
      distanceT: route.distanceTravelled,
      checkWalking:route.checkWalking      
    }
    
    const heaDis = sdkClient.geoService.getHeadingDistanceTo(closestRule.latitude, closestRule.longitude);
    result.angle = heaDis.heading;
    if (result.angle < 0) {
      result.angle = result.angle + 360;
    }
    currentAngle = result.angle;
    result.distance = (heaDis.distance * METER_MILES).toFixed(3);
    result.disMessage = `~ ${result.distance} mi away`
    
    if(result.distance >= MAX_THRESHOLD ) {
      result.status = "TO_FAR";
      result.message = "You are too far from any NFT. Get closer to Art Basel Miami Beach and try again!";
    } else {
      if(result.distance <=  FEET_THRESHOLD) {
        // convert to feet
        result.distance = (heaDis.distance * METER_FEET).toFixed(0);
        result.disMessage = `~ ${result.distance} ft away`
        result.measurment = "feet";
        result.disThresh = 3;
        if(result.distance <=  MIN_THRESHOLD) {
          result.disThresh = 4;
        }
      } else if(result.distance <= COLOR_1_THRESHOLD){
        result.disThresh = 1;
      }
      else if(result.distance <= COLOR_2_THRESHOLD){
        result.disThresh = 2;
      } else {
        result.disThresh = 0;
      }
    }
 
   // console.log(result);
    if( result.measurment === "feet" && result.distance <= 15) {
      if( result.status === MentaportTypes.Status.SUCCESS) {
        result.status = 'MINT';
      } else if( result.distance < 10) {
        result.distance = 8; // prevent user from seing 1 and geting frustrated
      }
    }
 
    return result;
  }
  catch(error) {
   // console.log(error);
    return {
      status:MentaportTypes.Status.ERROR,
      message:'We lost you for a second, please refresh this page.'
    };
  }
}

export async function TriggerMint(rule, wallet) {
  //console.log("TriggerMint", rule);
  try {
    const res = await sdkClient.triggerMintContractByRuleId(GetContractKey(), wallet, rule, 1);
    if(res.status === MentaportTypes.Status.SUCCESS) {
      return true;
    } return false;
    //retrun res;
  } catch(error) {
    //console.log("error:", error)
  }
}

///---------------------------------------------------------------------
//---------------------------------------------------------------------

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
