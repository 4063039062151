import React from "react"
import {Grid, Box, Container, Typography, Button} from '@mui/material';
import { LoadingButton } from '@mui/lab'
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Logo } from '../../assets/deca_logo.svg';

const InfoCard = (props) => {

  const { title, big, subtitle, info, info2, isMobile, useButton, buttonText, buttonInfo, CallbackCall } = props;
  const theme = useTheme();

  return (

    <Container component="main" sx={{ mt: 2, mb: 3, alignItems: 'center', 
    display: 'flex', flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      height: '50vh',
    },
    [theme.breakpoints.down('sm')]: {
      height:'68vh'
    },
    }} maxWidth="sm" >
      <Logo color={theme.palette.primary.main} width='280' /> 

      {big ? 
        <Typography color="inherit" align="center" variant="h1" marked="center" sx={{fontSize:70}} > 
        {title}
        </Typography>
      : (
      <Typography color="inherit" align="center" variant="h1" marked="center"  > 
       {title}
      </Typography>
      ) }
      {subtitle ? (
        <Typography color="inherit" align="center" variant="h3" sx={{ mb: 0, mt: 5}} >
          {subtitle}
        </Typography>
      ) :<></>}
      {isMobile ? ( 
        <Box align="center">
          <Typography color="inherit" align="center" variant="h3" sx={{ mb: 0, mt: 5 , width:'100%'}} >
            {info}
          </Typography>
          <Typography color="inherit" align="center" variant="h3" sx={{ mb: 5, mt: 0 , width:'100%'}} >
            {info2}
          </Typography >
          {useButton ? (
          <Button
            color="primary"
            variant="contained"
            size="large"
            component="a"
            onClick={CallbackCall}
            sx={{ minWidth: 200 }}
          >
           {buttonText}
          </Button>
          ):<></>} 
          <Typography variant="h6" color="inherit" sx={{ mt: 2, mb: 1}}>
          {buttonInfo}
          </Typography>
        </Box>
      )
      : 
      <Typography variant="h4" color="inherit" sx={{ mt: 2 }}>
        This experience is only available in mobile...
      </Typography>
    }

    </Container>
  );

}

export default InfoCard