import React from "react"

import { useState, useEffect } from 'react';
import {Box, Button, Typography} from '@mui/material';

import ModalMap from 'components/cards/Modal';
import InfoCard from 'components/cards/InfoCard';
import MintForm from 'components/cards/MintFormCard';
import LocationScreen from 'components/location';
import Footer from 'components/footer/Footer';

import LoadingIcons from 'react-loading-icons'
import { useTheme } from "@mui/material/styles";

import {InitMentaportSDK,LocationStarted, CheckIfCompatible, TriggerMint} from 'services/SDKCalls';
import {AddMintlistDB, AddLogslistDB,AddBlocklistDB, CheckAlreadyMinted,CheckBlock, GetAvailableLeft} from 'services/Database';
import {SendVerifiedEmail, CheckIfSignIn, GetUserData, RemoveData} from 'services/EmailAuthService';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth , Logout} from "services/firebase";
import { maxHeight } from "@mui/system";
//-----------------------------------
//-----------------------------------
const winner = "You found an XCOPY NFT!"
const winnerMessage = "" 
const winnerInfo = "Share on Twitter" 

const loser = "Sorry, this NFT was just claimed!" 
const loserMessage = "Check the map and zone counters to find unclaimed NFTs." 

const far = "You are too far!";
const farMessage="Get closer to Art Basel Miami Beach and try again."

const duplicate = "You have already claimed an NFT!" 
const duplMessage = "Enjoy the rest of Art Basel." 

const norules = "There are no active locations at this time." 
const noruleslMessage = "The event takes place from 9am to 9pm on Nov 30, Dec 01, and Dec 02" 

const beforeLive = "X" 
const beforeLiveMessage = "Register early for a chance to find TREASURE at Art Basel Miami Beach"
const beforeLiveInfo = "The event takes place from 9am to 9pm on Nov 30, Dec 01, and Dec 02" 
const beforeLiveMessage_2 = "Thank you for pre-registering for the treasure hunt!"

const EARLY_SCREEN = false;

export default function Home() {

  const theme = useTheme();
  //--------- check if returning user ------
  const [user, loading, error] = useAuthState(auth);
  const [stats, setStats] = useState({ZoneA:0, ZoneB:0, ZoneC:0});

  //--------- screen states ----------------
  const [isWinner, setIsWinner] = useState(false);
  const [introScreen, setIntroScreen] = useState(true);
  const [waitlistScreen, setWaitlistScreen] = useState(false);
  const [emailVerScreen, setEmailVerScreen] = useState(false);

  const [earlyScreen, setEarlyScreen] = useState(false);

  const [compassScreen, setCompassScreen] = useState(false);
  const [finishScreen, setFinishScreen] = useState(false);

  const [loadingLocation, setLoadingLocation] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  //--------- users info -------------------
  const [rule, setRules] = useState(-1);
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const [wallet, setWallet] = useState();
  const [isMobile, setIsMobile] = useState(false);
  //--------- text for views---------------
  const [finishTitle, setFinishTitle] = useState("");
  const [finishMessage, setFinishMessage] = useState("");
  const [finishInfo, setFinishInfo] = useState("");
  const [finishButton, setFinishButton] = useState("End");
  const [finishBtnInfo, setFinishBtnInfo] = useState("");


  const backgroundImage ="images/backgrounds/footer_background.png";

  const imgPaths = [
    "images/locations/deca_black.png", 
    "images/locations/deca.png", 
    "images/locations/Day_3.png", 
  ];

  //-----------------------------------------------------------
  useEffect(() => {
   // RemoveData()
    const checkSignLink = async () => {
     // console.log("checkSignLink")
      const data = await CheckIfSignIn();
      if(data.status == 'Success') {
        await setWallet(data.wallet);
        await setUsername(data.username);
        await setEmail(data.email);
        // set compass
        await setCompassAuth();
      } else if(data.status === 'Private') {
        // redirect to homepage
       alert('Looks like we are having issues with your browser. Open the link on a regular tab and try again!');
       window.location.href = '/';
      }
      else if(data.status == 'Error') {
        // redirect to homepage
       alert('Expired link, make sure to click the most recent verification email. It might take a few seconds to arrive.');
       window.location.href = '/';
      }
    }
  
    if (loading) {
      // maybe trigger a loading screen
      console.log("loading")
      checkSignLink();
    }
    else  {
      CheckUser();
    }

    // location timer
    const interval = setInterval(async () => {
      const status = LocationStarted();
      //console.log("LocationStarted", status)
      if(status.status == 'Start') {
        setLoadingLocation(true);
        setLoadingButton(false);
        if(compassScreen) {
          AddLogslistDB("Trying_init", email, username, wallet);
        }
        clearInterval(interval);
    
      } else if(status.status == 'Error') {
       
        window.location.href = '/';
        //alert("Something went wrong with your location, please refresh")
      }
    }, 1000);
    return () => clearInterval(interval);
 }, [loadingLocation, user]);


  //--------- AUTH / LOACTION FNC ----------------------------
  async function CheckPassedUser() {
    // check if blocked
    const br = await CheckBlock(email,username, wallet);
    // if true, user not blocked
    if(br.status) {
     // check if user hasnt already minted:
      const res = await CheckAlreadyMinted(email,username, wallet)
      return res;
    }
    return br;
  }

  async function LogTryingRule(rule, status) {
    // check if user hasnt already minted:
    const type = "Trying_"+ status;
    const ms = await AddLogslistDB(type, email, username, wallet, rule);
    return ms;
  }

  function CheckUser() {
    if (user) {
      //console.log("is user", user);
      // set info back from user and continue:
      const dd = GetUserData();
      const email = user.email;
      if(dd != null && email == dd.email) {
        setWallet(dd.wallet);
        setUsername(dd.username);
        setEmail(email);
        // set compass if coming from login
        return true;
      } else if(email != null) {
        //console.log(user)
        RemoveData();
      }
    }
    return false;
  }

  function SetLocation() {

    const m = CheckIfCompatible();
    setIsMobile(m);
    if (process.env.REACT_APP_STAGE === 'prod') {
      if(!m) {
        alert("Your device is not compatible with this experience. Please use a mobile device with GPS location.");
       return false;
      }
    } 

    // start location
    if(!InitMentaportSDK()) {
      // alert
      alert("Your device is not compatible with this experience. Please use a mobile device with GPS location.");
      return false;
    }
   
    if(LocationStarted().status !='Start') {
      setLoadingButton(true);
    }
    return true;
  }

  function SetZoneTimer(amountLeft) {
    return `Zone A: ${amountLeft.ZoneA} || Zone B: ${amountLeft.ZoneB} || Zone C: ${amountLeft.ZoneC}`
    setStats(amountLeft);
  }
  function GetZoneTimer() {
    return `Zone A: ${stats.ZoneA} || Zone B: ${stats.ZoneB}|| Zone C: ${stats.ZoneC}`
  }
  //-----------------------------------------------------------
  function ResetAll() {
    if(isWinner) {
      alert("Congratulations, you have been added to the mint list for an XCOPY NFT!")
    }
    setEarlyScreen(false);
    setIntroScreen(true);
    setCompassScreen(false);
    setWaitlistScreen(false);
    setFinishScreen(false);
    setIsWinner(false);
  }

  async function setCompassAuth() {
   
    // if(EARLY_SCREEN && (email != 'itsthomson@gmail.com' && email != 'cynthia@mentaport.com') ) {
    if(EARLY_SCREEN ) {     
      setIntroScreen(false);
      setEarlyScreen(true);
      return;
    }
    if(SetLocation()) {
      setEarlyScreen(false);
      
      setIntroScreen(false);
      setCompassScreen(true);
      setWaitlistScreen(false);
      setEmailVerScreen(false);
      setFinishScreen(false);
      // if location ready log now:
      if(LocationStarted().status =='Start') {
        AddLogslistDB("Trying_init", email, username, wallet);
      }
    } else {
      console.log("na");
    }
  }

  // Funtion to initiale the mint form to colllect users email wallet, username
  function StartMintList() {
    // if returnign user, send to compass
    if(CheckUser()) {
      setCompassAuth(true);
      return;
    }
    // else send to get information
    if(SetLocation()) {
     // RemoveData();
      setIntroScreen(false);
      setWaitlistScreen(true);
    }
  }

  // Function to log users info and if status true, send verification email
  async function AddInfolis(email, username, wallet, status) {
   
    setWaitlistScreen(false);
    setIntroScreen(false);

    setEmail(email);
    setWallet(wallet);
    setUsername(username);
   
    if(status) { 
      // go to email verification
      setEmailVerScreen(true);
     // console.log("AddInfolis")
      setLoadingButton(true);
      // test sending email
     // console.log("Send email test");
      const em = await SendVerifiedEmail(email, username, wallet);
      const ms = await AddLogslistDB("EmailSent", email, username, wallet);
      setLoadingButton(false);
    
    } else {
      //todo check
      setFinishScreen(true);
      setFinishTitle(loser);
      setFinishMessage(loserMessage);

      const amountLeft = await GetAvailableLeft();
      let stateInfo = GetZoneTimer();
      if(amountLeft != null) {
        stateInfo = SetZoneTimer(amountLeft);
      }
      setFinishInfo(stateInfo);
      const ms = await AddLogslistDB("ToFar", email, username, wallet);
    }
  }

  // Function to finish user flow and add to mint list if claimed
  async function AddMintlist(status, rule) {
   
    setRules(rule);
    setFinishScreen(true);
    setWaitlistScreen(false);
    setCompassScreen(false);

    setLoadingButton(true);
    if(status == 'found') { 
      const mint = await TriggerMint(rule.ruleId, wallet);

      if(mint) {
        const rest = await AddMintlistDB(email, username, wallet, rule);
    
        setFinishTitle(winner);
        setFinishMessage(winnerMessage);
        //setFinishInfo(message);
        setIsWinner(true);
        setFinishButton("Claim");
        setFinishBtnInfo('Dont forget to hit claim!')
        await RemoveData();
      } else {
        const ms = await AddLogslistDB("FailedLoser", email, username, wallet);
        setFinishTitle(loser);
        setFinishMessage(loserMessage);
        
        const amountLeft = await GetAvailableLeft();
        let stateInfo = GetZoneTimer();
        if(amountLeft != null) {
          stateInfo = SetZoneTimer(amountLeft);
        }
        setFinishInfo(stateInfo);
        setFinishButton("End");
        setFinishBtnInfo('');
      }
    } else {

      setFinishBtnInfo('');
      const amountLeft = await GetAvailableLeft();
      let stateInfo = GetZoneTimer();
      if(amountLeft != null) {
        stateInfo = SetZoneTimer(amountLeft);
      }
      setFinishInfo(stateInfo);
      if(status == 'loser') {
        const ms = await AddLogslistDB("FailedLoser", email, username, wallet);
        setFinishTitle(loser);
        setFinishMessage(loserMessage);
        setFinishButton("End");
      }
      else if(status == 'norules') {
        setFinishTitle(norules);
        setFinishMessage(noruleslMessage);
        setFinishButton("End");
      }
      else if(status == 'far') {
        // it should never hit this from info list
        setFinishTitle(far);
        setFinishMessage(farMessage);
        setFinishButton("End");
      
        const ms = await AddLogslistDB("ToFar", email, username, wallet);
      } else if(status == 'duplicate') {
        // it should never hit this from info list
        setFinishTitle(duplicate);
        setFinishMessage(duplMessage);
        setFinishButton("End");
        const ms = await AddLogslistDB("TryDuplicate", email, username, wallet);
      } else if(status == 'block') {
        // reset normal
        ResetAll();
        // log
        const bs = await AddBlocklistDB(email, username, wallet);
        const ms = await AddLogslistDB("Blocked", email, username, wallet);
      }
      else {
       
        ResetAll();
      // await Logout();
      }
    }

    setLoadingButton(false);
  }

  //-------- UI VIEWS ------
  function IntroView() {
    if(introScreen && EARLY_SCREEN) {
      return ( 
        <InfoCard title={beforeLive} big={true}
        subtitle={beforeLiveMessage} 
        info ={beforeLiveInfo} isMobile={true} useButton={true}
        buttonText = {"Start"}
        buttonInfo = {"Make sure you allow location and compass access!"}
        CallbackCall= {StartMintList} 
        />
      );
    }
    else if(introScreen ) {
      return ( 
        <InfoCard title={'X MARKS THE SPOT'} 

          info={`Find TREASURE at Art Basel Miami`} 
          info2={`Just 15 XCOPYs available each day`} 
          isMobile={true} useButton={true}
          buttonText = {"This event has ended"}
          buttonInfo = {""}
          CallbackCall= {""} 
        />
      );
    }
    return <></>
  }
 
  function MintListView() {
     if(waitlistScreen && loadingButton) {
     
      return (
      <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="72vh"
          flexDirection= 'column'
          sx={{ m:'auto'}} >
          
          {/* <Typography color="inherit" align="center" variant="h5" sx={{mt: 3, mb:3, ml: 5, mr: 5}}>
            {'Loading location...'}
          </Typography> */}
        
            <LoadingIcons.Circles stroke={'transparent'} fill={theme.palette.secondary[800]} speed={.75} width={80} height={''}/>
        </Box>
      );
    }
    else if(waitlistScreen) {
      return (
        <MintForm title="Let's start the hunt!" info={'You must have a '} 
        linkInfo={"Deca account" } info2={' to play. Add your info below to begin.'} image={imgPaths[1]} CallbackCall= {AddInfolis}/>
      );
    }
    return <></>
  }

  function EmailVerView() {
    if(emailVerScreen && loadingButton) {
      return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="72vh"
        flexDirection= 'column'
        sx={{ m:'auto'}} >
          <LoadingIcons.Circles stroke={'transparent'} fill={theme.palette.secondary[800]} speed={.75} width={80} height={''}/>
      </Box>
      );
    }
    if(emailVerScreen) {
      return (
        <InfoCard title={'X Marks the Spot'} 
          subtitle={"Check your email to continue..."} 
          info={``} 
          info2={`Look in your inbox and spam folder (it might take a few seconds)`} 
          isMobile={true} useButton={false}
          buttonText = {"Done"}
          buttonInfo = {""}
          CallbackCall= {StartMintList} 
        />
      );
    }
    return <></>
  }
  function EarlyBird() {
    if(earlyScreen && loadingButton) {
      return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="72vh"
        flexDirection= 'column'
        sx={{ m:'auto'}} >
          <LoadingIcons.Circles stroke={'transparent'} fill={theme.palette.secondary[800]} speed={.75} width={80} height={''}/>
      </Box>
      );
    }
    else if(earlyScreen) {
      return (
        <InfoCard title={beforeLive} big={true}
          subtitle={beforeLiveMessage_2} 
          info ={beforeLiveInfo} isMobile={true} useButton={false}
          buttonText = {""}
      />
      );
    }
    return <></>
  }
  
  function LocationView() {
    if(compassScreen && loadingButton) {
      return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="72vh"
        flexDirection= 'column'
        sx={{ m:'auto'}} >
          <LoadingIcons.Circles stroke={'transparent'} fill={theme.palette.secondary[800]} speed={.75} width={80} height={''}/>
      </Box>
      );
    }
    else if(compassScreen) {
      return (
        <LocationScreen title='X MARKS THE SPOT' 
          CallbackCall = {AddMintlist} 
          VerificationCall={CheckPassedUser} 
          LogTryingRule={LogTryingRule} />
      );
    }
    return <></>
  }
  function FinishView() {
   
    if(finishScreen && loadingButton) {
      return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="72vh"
        flexDirection= 'column'
        sx={{ m:'auto'}} >
          <LoadingIcons.Circles stroke={'transparent'} fill={theme.palette.secondary[800]} speed={.75} width={80} height={''}/>
      </Box>
      );
    }
    else if(finishScreen) {
      return (
        <InfoCard title={finishTitle} 
          subtitle={finishMessage} 
          info ={finishInfo} isMobile={true} useButton={true}
          CallbackCall= {ResetAll} buttonText = {finishButton} buttonInfo = {finishBtnInfo}
      />
      );
    }
    return <> </>
  }


  //---------------------------------------------------------
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          height: '100vh',
        },
        [theme.breakpoints.down('sm')]: {
         // minHeight: '80vh',
          height:'90vh'
        },
        backgroundColor: 'common.black',
        opacity: 0.8,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage})`
      }}
    >
    {/* {!EARLY_SCREEN &&  
    ( <ModalMap title={"Day 3"} info={""} btnText={"Open Map"} useImg={true} link={imgPaths[2]}/> ) } */}
      {/* INTRO VIEW */}
      <IntroView />
      {/* MINT VIEW */}
      <MintListView />
      {/* EMAIL VIEW */}
      <EmailVerView/>
    
      {/* EARLY VIEW */ }
      {/* <EarlyBird /> */}
    
      {/* LOCATION VIEW */
      <LocationView />}
      {/* MINT VIEW */}
      <FinishView />
      {/* FOOTER */}
      <Footer />
    
    </Box>
  );
}