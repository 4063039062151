import * as React from 'react';
import { useState, useEffect } from 'react';
import {Typography, Button, Box, Grid} from '@mui/material/';

import ModalInfo from 'components/cards/Modal';
import LoadingIcons from 'react-loading-icons'
import Compass from './Compass';

import {InitMentaportSDK, CheckLocationDisHeading, CheckIfCompatible } from 'services/SDKCalls';
import {GetAvailableLeft} from 'services/Database';

import { StartCompass, StopCompass , CheckTime} from "../../services/CompassTracker"
import { useTheme } from "@mui/material/styles";


export default function Location(props) {
  const { title, CallbackCall, VerificationCall, LogTryingRule} = props;
  const theme = useTheme();
  
  const [tracking, setTracking] = useState(false);
  const [loading, setLoading] = useState(false);

  const [stats, setStats] = useState({ZoneA:0, ZoneB:0, ZoneC:0});
  const [disMessage, setDisMessage ] = useState("");

  const [disThresh, setDisThresh]= useState(0);
  const [angle, setAngle] = useState(0);
  const [ruleId, setRuleId] = useState('na');
  const [ruleInfo, setRuleInfo] = useState('na');

  const [statusGeo, setStatusGeo] = React.useState("");

//---------------------------------------------------------------------
  async function BeginLoading() {
    // start location
    if(!InitMentaportSDK()) {
      // alert
      alert("Sorry your device is not compatible. Please use a mobile device with GPS location.");
      return;
    }
    const res = await StartCompass(CheckIfCompatible());
   // check user first
    const pass = await VerificationCall();
    if(!pass.status) {
      if(pass.msg === 'dupliate') {
        alert("Sorry your have already minted an NFT.");
        CallbackCall('duplicate',-1);
        if(res) {
          StopCompass();
        }
      } else {
        //console.log(pass.msg)
        alert("Sorry we can't verify your location, refresh and try again.");
        return;
      }
    }
    
    if(res) {
     setLoading(true);
    }
  }

  function FoundLocation() {
    StopCompass();
    CallbackCall('found', {'ruleId':ruleId, 'ruleInfo':ruleInfo});
  }
  function FarLocation() {
    StopCompass();
    CallbackCall('far',  {'ruleId':ruleId, 'ruleInfo':ruleInfo});
  }
  function NoRuleLocation() {
    StopCompass();
    CallbackCall('norules',  {'ruleId':ruleId, 'ruleInfo':ruleInfo});
  }
  function FailedBlock() {
    StopCompass();
    CallbackCall('block', {'ruleId':ruleId, 'ruleInfo':ruleInfo});
  }
  function FailedNA(name) {
    StopCompass();
    CallbackCall(name, {'ruleId':ruleId, 'ruleInfo':ruleInfo});
  }
  function LoadingScreen() {
    if(loading) {
      return(
        <Box sx={{minHeight:'600' , pt:1}} align="center">
          <Typography color="inherit" variant="h3" marked="center" sx={{mb:5}}>
          Your compass is loading...
          </Typography>
          <LoadingIcons.Circles stroke={'transparent'} fill={theme.palette.secondary[800]} speed={.75} width={80} height={''}/>
          <Typography color="inherit" variant="h4" marked="center" sx={{mb:5, mt: 2, mr: 5, ml: 5}}>
            Stay aware of your surroundings. Do not enter dangerous areas while NFT hunting and be respectful of people around you!
          </Typography>
      </Box>
      );
    }
    else {
      return(
        <Box sx={{p:2, mb:1, mt:0}} align="center">
          <Typography color="inherit" variant="h2" marked="center" sx={{mt:0, mb: 4}}>
            Explore the map zones for available NFTs
          </Typography>
       
          <Box sx={{ mb:4, mt:0}} align="center">
            <Typography color="inherit" variant="h4">
            {`NFTs Remaining today`}
            </Typography>
            <Typography color="inherit" variant="h5">
            {`Zone A: ${stats.ZoneA} || `} 
            {`Zone B: ${stats.ZoneB} ` } 
            {`|| Zone C: ${stats.ZoneC} ` } 
            </Typography>
          </Box>
         
          <Button variant="contained" onClick={BeginLoading}  sx={{pl:2, pr:2}}>Start the hunt</Button>
          <Typography color="inherit" variant="h4" marked="center" sx={{mt:2, mb:1, mr: 5, ml: 5}}>
          Make sure to share location and compass, we only use them while you are on this webapp!
          </Typography>
          {/* <Typography color="inherit" variant="h5" marked="center" sx={{mb:1, mt: 0, mr: 5, ml: 5}}>
           Your location and compass are only used while you are on this webapp.
          </Typography> */}

        </Box>
      );
    }
  }

  useEffect(() => {
    
    const getStat = async () => {
      const amount = await GetAvailableLeft();
      if(amount != null) {
        setStats(amount);
      }     
    }
    getStat();

    const interval = setInterval(async () => {
      const amount = await GetAvailableLeft();
      let checkRules = false;
      if(amount != null) {
        if(amount != stats) {
          setStats(amount);
          checkRules = true;
        }
      }   
     
      if(loading || tracking) {
        const res = await CheckLocationDisHeading(checkRules);
       
        if(res.status === 'Error') {
         // console.log(res.message);
          alert(res.message)
         // FailedCompass();
          return;
        }
        if(res.status === 'Block') {
          // console.log(res.message);
           alert(res.message)
           FailedBlock();
           return;
         } else if(res.status === 'ERROR') {
          alert(res.message)
          FailedNA('na');
          return;
        }
        else if(res.status === 'LOADING') {}
        else if(res.status === 'TO_FAR') {
          alert(res.message);
          // redirect home:
          FarLocation();
         return;
        }
        else if(res.status === 'NO_RULES') {
          alert(res.message);
          // redirect home:
          NoRuleLocation();
         return;
        }
        else {
          
          if(!CheckTime()) {
            //console.log('here')
            alert("We can't verify your location. Refresh your page and try again.");
            FailedNA('na');
          return;
          }
          if(disThresh != res.disThresh) {
            setDisThresh(res.disThresh)
          }
          if(angle != res.angle) {
            setAngle(res.angle);
          }
          if(ruleId != res.ruleId) {
           //console.log("setting rule", res.ruleId);
            setRuleId(res.ruleId);
            setRuleInfo(res.ruleInfo);
            await LogTryingRule({'ruleId':res.ruleId, 'ruleInfo':res.ruleInfo}, res.status);
          }
          // update staatus of hunt
          setDisMessage(res.disMessage);
          if( res.status === 'MINT') {
            // before minting: double check not trying two same time:
            const pass = await VerificationCall();
            
            if(!pass.status) {
              StopCompass();
              if(pass.msg === 'duplicate') {
                alert("Sorry your have already minted an NFT.");
                CallbackCall('duplicate', {'ruleId':res.ruleId, 'ruleInfo':res.ruleInfo});
              } else {
                //alert("Sorry we're having some difficulties, refresh and try again.");
                FailedNA('loser');
              }
              return;
            }
            //console.log("EndCompass ", res.distance)
            FoundLocation();
            return;
          }
          if(loading && res.latitude != 0) {
            setLoading(false);
            setTracking(true);
          }
         
         //const geo = "lat: "+ res.latitude + " long: " +res.longitude+ " rule: " + res.ruleId; 
         // const geo = "walking " +res.walking + " checkW  "+ res.checkWalking +" dis " + res.distanceT;
          //setStatusGeo(geo)
        }
      }
    }, 3000);
  
    return () => clearInterval(interval);
  },[loading]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection= 'column'
      sx={{ m:'auto',
    }}
    >
    <Grid container justifyContent="center" direction="row" sx={{ ml:0, mb:2, mt:0}} >
      <Grid item xs={12}  align="center" >  
        <Typography color="inherit" variant="h1"  >
        {title}
        </Typography>
      </Grid>
    </Grid>
      {tracking? ( 
      <>
       <Grid item xs={12} align="center" sx={{ mr:0, mb:3}} > 
       <Typography color="inherit" variant="h4">
          {`NFTs Remaining today`}
          </Typography>
          <Typography color="inherit" variant="h5">
            {`Zone A: ${stats.ZoneA} || `} 
            {`Zone B: ${stats.ZoneB} ` } 
            {`|| Zone C: ${stats.ZoneC} ` } 
            </Typography>
        </Grid>

        {/* <Typography color="inherit" align="center" variant="h5" sx={{mt: 3, mb:3, ml: 5, mr: 5}}>
          {'To locate an NFT, align the green and purple points and walk in that direction. '}
        </Typography> */}
       
        <Compass direction={angle} color={disThresh} />
        <Typography color="inherit" align="center" variant="h2" sx={{mt:1, mb:1}}>
          {disMessage}
        </Typography> 
        
        <ModalInfo title={"Compass"} info ={'To locate an NFT, align the green and purple points and walk in that direction.'} info2={"The rotating point represents the nearest unclaimed XCOPY NFT.The purple point is the direction you are traveling."} btnText={"Instructions"} useImg={false}/>
        {/* <Button onClick ={FoundLocation}>test</Button> */}
        {/* <Typography color="inherit" align="center" variant="h5" sx={{mt: 3, mb:3, ml: 4, mr: 4}}>
          {'The rotating point represents the nearest unclaimed XCOPY NFT.The purple point is the direction you are traveling. '} <br /><br /> 
        </Typography> */}
        <Typography color="inherit" align="center" variant="h5" sx={{mt: 2, mb:5, ml: 5, mr: 5}}>
          {'Stay aware of your surroundings.'}
        </Typography>

          {/* <Typography color="inherit" variant="h5">
          {statusGeo}
          </Typography> */}
      </>
      ):
      <LoadingScreen />
      }
    </Box>
  );
}
