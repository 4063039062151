
import { db, auth, Logout} from "./firebase";
import { doc, getDoc,getCountFromServer } from "firebase/firestore";
import { collection, query, where, onSnapshot, addDoc } from "firebase/firestore";
import {GetUserInfo} from "./SDKCalls";

const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];


async function GetSnapshopCount(query) {
  const snapshot = await getCountFromServer(query);
  return snapshot.data().count;
}

export async function CheckAlreadyMinted(email, username, wallet) {
 
  try {
    if (process.env.REACT_APP_STAGE === 'local' || process.env.REACT_APP_STAGE === 'staging' ) {
      return {status:true};
    }
    const info = GetUserInfo();
    // check that this user hasnt mint before
    const listA = process.env.REACT_APP_MINT_ALL;
    const dbRefAll = collection(db, listA);
    const qip = query(dbRefAll, where("ip", "==", info.ip));
    let count = await GetSnapshopCount(qip);
    // console.log("ip", count)
    if(count > 0) {
      return {status:false, msg:"duplicate"};
    }
    const qemail = query(dbRefAll, where("email", "==", email));
    count = await GetSnapshopCount(qemail);
    // console.log("email", count)
    if(count > 0) {
      return {status:false, msg:"duplicate"};
    }
    const quser = query(dbRefAll, where("username", "==", username));
    count = await GetSnapshopCount(quser);
    // console.log("username", count)
    if(count > 0) {
      return {status:false, msg:"duplicate"};
    }
    const qwallet = query(dbRefAll, where("wallet", "==", wallet));
    count = await GetSnapshopCount(qwallet);
    // console.log("wallet", count)
    if(count > 0) {
      return {status:false, msg:"duplicate"};
    }
    return {status:true};
    
  } catch (error) {
    return {status:false, msg:"error"};
  }
}

export async function CheckBlock(email, username, wallet) {
 
  try {
    if (process.env.REACT_APP_STAGE === 'local' || process.env.REACT_APP_STAGE === 'staging' ) {
      return {status:true};
    }
    const info = GetUserInfo();
    // check that this user is not blocked

    const listA = process.env.REACT_APP_BLOCK_ALL;
    const dbRefAll = collection(db, listA);
    
    const qip = query(dbRefAll, where("ip", "==", info.ip));
    let count = await GetSnapshopCount(qip);
    // console.log("ip", count)
    if(count > 0) {
      return {status:false, msg:"block"};
    }
    const qwallet = query(dbRefAll, where("wallet", "==", wallet));
    count = await GetSnapshopCount(qwallet);
    // console.log("wallet", count)
    if(count > 0) {
      return {status:false, msg:"block"};
    }

    const qemail = query(dbRefAll, where("email", "==", email));
    count = await GetSnapshopCount(qemail);
    // console.log("email", count)
    if(count > 0) {
      return {status:false, msg:"block"};
    }
    const quser = query(dbRefAll, where("username", "==", username));
    count = await GetSnapshopCount(quser);
    // console.log("username", count)
    if(count > 0) {
      return {status:false, msg:"block"};
    }
   
    return {status:true};
    
  } catch (error) {
    return {status:false, msg:"error"};
  }
}

export async function GetAvailableLeft() {
  try {
    
    const d = new Date();
    const zoneA = "ZoneA_Friday";// + weekday[d.getDay()]
    const zoneB = "ZoneB_Friday";// + weekday[d.getDay()]
    const zoneC = "ZoneC_Friday";// + weekday[d.getDay()]

   // return { ZoneA:0, ZoneB:0, ZoneC:0 }

    let dbRef = collection(db, zoneA);
    const countA = await GetSnapshopCount(dbRef);

    dbRef = collection(db, zoneB);
    const countB = await GetSnapshopCount(dbRef);
    dbRef = collection(db, zoneC);
    const countC = await GetSnapshopCount(dbRef);

    return { ZoneA:(9 - countA), ZoneB:(3 - countB), ZoneC:(3 - countC) }
  
  } catch(error) {
   // console.log("error in getting available left", error);
    return  null;
  }
}

export async function AddMintlistDB (email, username, wallet, rule) {
    // add extra columns for analytics here and the lines below (1/2)
    // no lging in testing
    if (process.env.REACT_APP_STAGE === 'local') {
      return true;
    }
    const info = GetUserInfo();
    const d = new Date();
    const data = {
      timestamp: Date.now(),
      date: d.toUTCString(),
      email:email,
      username:username,
      wallet:wallet,
      location_rule:rule,
      ip:info.ip?info.ip :"",
      // ip_lat:info.latitude ? info.latitude: "",
      // ip_long:info.longitude ? info.longitude: "",
      // ip_city:info.city?info.city:"",
    };
    try {
    // auth needed
    const list = process.env.REACT_APP_MINT + "_Friday";// + weekday[d.getDay()];
    
    const dbRef = collection(db, list);
    const doc = await addDoc(dbRef, data);
    //console.log("Document has been added successfully");
    // save in all db
    const listA = process.env.REACT_APP_MINT_ALL;
    const dbRefAll = collection(db, listA);
    const docA = await addDoc(dbRefAll, data);

    // add logs
    await AddLogslistDB("Sucess",email, username, wallet, rule, doc.id );

    // add zone tracker:
    const zone = rule.ruleInfo.split("_");
    const table = zone[0] + "_Friday"; // + weekday[d.getDay()]
    const dbRefZ = collection(db, table);
    const dzone = {
      username:username,
      nft: zone[1],
    }

    await addDoc(dbRefZ, dzone);

    return true;
  } catch (error) {
    //console.log(error)
    //return 'fail'
    throw Error('Something went wrong, please try again.')
  }
}

export async function AddLogslistDB (status, email, username, wallet, rule, doc) {

  if (process.env.REACT_APP_STAGE === 'local') {
    return true;
  }
  
  // add extra columns for analytics here and the lines above (2/2)
  // console.log("AddLogslistDB", status)
  const info = GetUserInfo();
  //console.log(info)
  const d = new Date();
  const data = {
    timestamp: Date.now(),
    date: d.toUTCString(),
    status:status,
    email:email,
    username:username,
    wallet:wallet,
    latitude:info.latitude,
    longitude:info.longitude,
    ip:info.ip?info.ip :"",
    ip_lat:info.ip_lat ? info.ip_lat: "",
    ip_long:info.ip_lon ? info.ip_lon: "",
    ip_city:info.city ? info.city:"",
    location_rule: rule? rule : "",
    mintlist_doc: doc? doc : "",
  };
  
  try {
    const list = process.env.REACT_APP_LOGS + "_Friday";// + weekday[d.getDay()];
    // console.log("Document tryig to add");
    const dbRef = collection(db, list);
    const docRef = await addDoc(dbRef, data);
    //console.log("Document has been added successfully");
    //return "You have been successfully added.";
    return;
  } catch (error) {
    console.log(error);
    //throw Error('We are having some difficulties at the moment, please come back later!')
    //return 'fail'
  }
}

export async function AddBlocklistDB (email, username, wallet) {
  // block user
  const info = GetUserInfo();
  const d = new Date();
  const data = {
    timestamp: Date.now(),
    date: d.toUTCString(),
    email:email,
    username:username,
    wallet:wallet,
    ip:info.ip,
  };

  try {
    // auth needed
    const list = process.env.REACT_APP_BLOCK + "_Friday";// + weekday[d.getDay()];
    
    const dbRef = collection(db, list);
    const doc = await addDoc(dbRef, data);
    //console.log("Document has been added successfully");
    // save in all db
    const listA = process.env.REACT_APP_BLOCK_ALL;
    const dbRefAll = collection(db, listA);
    const docA = await addDoc(dbRefAll, data);

    return true;
  } catch (error) {
    //console.log(error)
    //return 'fail'
    throw Error('Something went wrong, please try again.')
  }
}
