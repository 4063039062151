import React from 'react';
import { useState, useEffect } from 'react';
import './styles.scss';
import {Typography, Button, Box} from '@mui/material/';

const directionNames=  ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']
 // far - closer -closer
const colors= ['#fff', '#cbe9d8','#a8e9d7','#83e4c8', '#48e4b8']

function Compass(props) {

	const [oldAngle, setOldAngle] = useState(0);
	const [name, setName] = useState("");
	const [color, setColors] = useState(0);
	
	function directionName(dir) {
		let sections = directionNames.length,
			sect = 360 / sections,
			x = Math.floor((dir + sect / 2) / sect);

		x = x >= sections ? 0 : x;
		return directionNames[x];
	}

	function normalizeAngle(direction) {

		let newAngle = direction,
				rot = oldAngle || 0,
				ar = rot % 360;

		while (newAngle < 0) { newAngle += 360; }
		while (newAngle > 360) { newAngle -= 360; }
		while (rot < 0) { rot += 360; }
		while (rot > 360) { rot -= 360; }

		if (ar < 0) { ar += 360; }
		if (ar < 180 && newAngle > ar + 180) { rot -= 360; }
		if (ar >= 180 && newAngle <= ar - 180) { rot += 360; }

		rot += newAngle - ar;
		setOldAngle(rot)

		return rot;
	}

	useEffect(() => {
		let dir = props.direction;//normalizeAngle(props.direction);
		setName(directionName(dir));
	},);

		return (
			<div className="compass__windrose">
			<div className="compass">

				{/* <div className="compass_move"> */}
				
						<div className="compass_inside" >
							{[...Array(10)].map((k, i) => <div className="compass__mark" key={i + 1}></div>)}
							<div className="compass__mark--direction-h"></div>
							<div className="compass__mark--direction-v"></div>
						</div>
					</div>

				{/* style={{ transform: `translate(-50%, -50%) rotate(${props.direction}deg)` }} > */}
					<div className="compass__arrow-container" >
						<div className="compass__arrow" style ={{ background:`${colors[props.color]}` }}></div>
						<div className="compass__labels">
							<span>{name}</span>
						</div>
					</div>
				{/* </div> */}

			</div>
		);
	//}
}


export default Compass;