
import {GetAngle} from './SDKCalls';


let compassCircle = document.querySelector(".compass"); 
//let compassCircle = document.querySelector(".compass__arrow-container"); 
let compassArrow = document.querySelector(".compass__arrow-container");

let currAngle = null;
let deltaAngle = null;
let timestam = null;

export async function StartCompass(isMobile) {
 // console.log("start compass")

  if(!isMobile) {
    window.addEventListener("deviceorientationabsolute", compassHandler, true);
    return true;
  } else if(window.DeviceOrientationEvent) {
		try {
      if (typeof DeviceMotionEvent.requestPermission === 'function') {
        // iOS 13+
        const response = await window.DeviceOrientationEvent.requestPermission();
        if (response === "granted") {
          window.addEventListener("deviceorientation", compassHandler, true);
          return true;
        }
        return false;
      } else {
        window.addEventListener("deviceorientation", compassHandler, true);
        return true;
      }
		}
   	catch(error) {
			alert("Compass not supported.")
			console.error(error.message);
			return false;
    }
  }
}

export function compassHandler(e) {
  timestam =  Date.now();
  const compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
  if(compassCircle == null) {
    compassCircle = document.querySelector(".compass");
    //compassCircle = document.querySelector(".compass__arrow-container");
    compassArrow = document.querySelector(".compass__arrow-container");
    currAngle = compass;
    return;
  }
  let pointAngle = GetAngle();
  if(isNaN(pointAngle)) { return;}
 
  // Check for wrap over the 0 degree mark
  // if(currAngle > 270 && compass  < 90) {
  //   deltaAngle = compass + 360 - currAngle;
  // } else if(compass > 270 && currAngle < 90){
  //   deltaAngle = -(currAngle + 260 - compass);
  // } else {
  //   deltaAngle = compass - currAngle;
  // }
  // currAngle += deltaAngle * 0.85; // Alter the constant to change easing rate
 
  //currAngle = compass;
  //console.log(tmpAngle,(currAngle - tmpAngle) )
  pointAngle = 360 - (compass - pointAngle);
  //console.log(compass, tmpAngle )
  compassCircle.style.transform = `rotate(${-compass}deg)`;
  compassArrow.style.transform = ` rotate(${pointAngle}deg)`;
  
  
  // const pointDegree = GetAngle();
  // if(isNaN(pointDegree)) { return;}translate(-50%, -50%) 
  // // check angle: ±15 degree
  // if (
  //   (pointDegree < Math.abs(compass) &&
  //     pointDegree + 15 > Math.abs(compass)) ||
  //   pointDegree > Math.abs(compass + 15) ||
  //   pointDegree < Math.abs(compass)
  // ) {
  //  // compassArrow.style.opacity = 0;
  // } else if (pointDegree) {
  //  // compassArrow.style.opacity = 1;
  // }
}


export function CheckTime() {
  if(timestam == null)
  { return false;}
  if(Date.now() - timestam > 2000){
    console.log(Date.now() - timestam )
    return false;
  }
  return true;
}
export function StopCompass() {
  console.log('stop compass')
  window.removeEventListener("deviceorientation", compassHandler, true);
}
