import React from "react"
import { useState } from 'react';
import {Button,CircularProgress, Card, CardActions, CardContent, CardMedia} from '@mui/material';
import {Grid, Box, Container, Typography, Link, Stack} from '@mui/material';
import { ReactComponent as Logo } from 'assets/deca_logo.svg';
import {CheckAuthCredentials} from 'services/EmailAuthService'
import {AddLogslistDB} from 'services/Database';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

import { LoadingButton } from '@mui/lab'

import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTheme } from "@mui/material/styles";


const MintFormCard = (props) => {

  const {id, image, title, info, info2, link, linkInfo, CallbackCall} = props;
  const [verifyInfo, setVerifyInfo] = useState(false);
  const theme = useTheme();


  // function Test() {
  //   CallbackCall('mariale@mentaport.com', 'username', 'address', true);
  // }
  // function TestCynthia() {
  //   CallbackCall('cynthia@mentaport.com', 'username', 'address', true);
  // }
  
  async function VerifyInfo(email, username, address) {

    setVerifyInfo(true);
    const result = await CheckAuthCredentials(username, address);
    setVerifyInfo(false);
  
    if(result) {
      alert("Important, verify your email to continue!");
      CallbackCall(email, username, address, true);
    } else {
      alert("Sorry, your information is not correct. If you don’t have a Deca account, sign up at deca.art/signin.")
      //log bad info
      AddLogslistDB("BadInfo", email, username, address);
    }
  }

  return (
  
    <Container component="main" sx={{ mt: 3, mb: 2, alignItems: 'center', display: 'flex', flexDirection: 'column' }} maxWidth="sm" >
      <Card sx={{  display: 'flex', flexDirection: 'column', maxWidth: '90vw', pt:0, pb:0, pr:0.5, pl:0.5 }} >
        <Container component="div" sx={{alignItems: 'center', display: 'flex', flexDirection: 'column' }} maxWidth="sm">
          <Logo color={theme.palette.primary.main} width='150' height='150'/> 
        </Container>
        {/* <CardMedia component="img" image={image} alt="Logo" sx={{height:'120px', width:'120px', alignItems:'center'}} /> */}
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography sx={{ fontSize: 22, mt:0 }} color="text.primary" gutterBottom>
          {title}
        </Typography>
        <Typography sx={{ fontSize: 16}} color="text.primary" gutterBottom>
          {info}
          <Link color="inherit" href="https://www.deca.art/signin" target="_blank" >
            {linkInfo}
          </Link>{' '}
          {info2}
        </Typography>
        <Formik
          initialValues={{
            email: '',
            username:'',
            address: '',
            check:false,
            submit: null
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            username: Yup.string().min(1).max(255).required('Deca username is required'),
            address: Yup.string().min(4).max(255).required('Wallet address is required'),
            check: Yup.boolean().oneOf([true],'Accept terms & conditions to continue')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setStatus({ success: true });
              setSubmitting(false);
              console.log(values);
              VerifyInfo(values.email, values.username, values.address);
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit} >
        {/* EMAIL FORM */}
        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
          <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
          <OutlinedInput
            id="outlined-adornment-email"
            type="email"
            value={values.email}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            label="Email"
            inputProps={{}}
          />
          {touched.email && errors.email && (
            <FormHelperText error id="standard-weight-helper-text-email">
              {errors.email}
            </FormHelperText>
          )}
        </FormControl>
        {/* USERNAME FORM */}
        <FormControl
          fullWidth
          error={Boolean(touched.username && errors.username)}
          sx={{ ...theme.typography.customInput }}
        >
        <InputLabel htmlFor="outlined-adornment-username">Deca username</InputLabel>
        <OutlinedInput
          id="outlined-adornment-username"
          type='text'
          value={values.username}
          name="username"
          onBlur={handleBlur}
          onChange={handleChange}
          label="Username"
          inputProps={{}}
        />
        {touched.username && errors.username && (
          <FormHelperText error id="standard-weight-helper-text-username">
            {errors.username}
          </FormHelperText>
        )}
      </FormControl>
      {/* ADDRESS FORM */}
      <FormControl
        fullWidth
        error={Boolean(touched.address && errors.address)}
        sx={{ ...theme.typography.customInput }}
      >
        <InputLabel htmlFor="outlined-adornment-address">Wallet address (as on Deca)</InputLabel>
        <OutlinedInput
          id="outlined-adornment-address"
          type='text'
          value={values.address}
          name="address"
          onBlur={handleBlur}
          onChange={handleChange}
          label="Address"
          inputProps={{}}
        />
        {touched.address && errors.address && (
          <FormHelperText error id="standard-weight-helper-text-address">
            {errors.address}
          </FormHelperText>
        )}
      </FormControl>
      {/* TERMS & CONTIDITIONS FORM */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
      <FormControl required
        fullWidth
        error={Boolean(touched.check && errors.check)}
        sx={{ ...theme.typography.customInput}}
      >
        <FormControlLabel
         labelPlacement="start"
          control={
            <Checkbox
              checked={values.check}
              onChange={handleChange}
              name="check"
              color="secondary"
              
              style={{transform: "scale(.8)", backgroundColor:'#F2F2F2'}}
            />
          }
          label={<Typography variant="subtitle2" sx={{ml:-2}}>
            By signing up for the hunt, I agree to the Terms and Conditions of 
            <Link href="https://www.mentaport.xyz/x-marks-the-spot-terms-and-conditions"  target="_blank" sx={{ ml: 1, mr: 1}}  style={{ textDecoration: 'underline' }} > 
            X Marks the Spot
            </Link>
            and
            <Link href="https://www.mentaport.xyz/terms-of-service"  target="_blank" sx={{ ml: 1, }}  style={{ textDecoration: 'underline' }} > 
            Mentaport 
            </Link>
          </Typography>}
        />
         {touched.check && errors.check && (
          <FormHelperText error id="standard-weight-helper-text-check">
            {errors.check}
          </FormHelperText>
        )}
        {/* {!check && (
          <FormHelperText error id="standard-weight-helper-text-check">
            {"* Required to accept terms & conditions"}
          </FormHelperText>
        )} */}
       </FormControl>
      </Stack>
      {errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{errors.submit}</FormHelperText>
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        <Button disabled={verifyInfo}
          disableElevation
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="primary">
        {verifyInfo && <CircularProgress size={34} />}
        {!verifyInfo && 'Submit'}
      </Button>
      {/* <Button onClick ={Test}>test-M</Button>
      <Button onClick ={TestCynthia}>test-c</Button> */}
        </Box>
        </form>
      )}
    </Formik>
    </CardContent>        
  </Card>

  </Container>
  );

}

export default MintFormCard