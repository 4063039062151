
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Home from './pages/Home';

// defaultTheme
import themes from './themes';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes()}>
      <CssBaseline />
        <Home />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
